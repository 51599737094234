import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SidebarForm from "../components/SidebarForm";
import Seo from "../components/seo"
import ReactMarkdown from 'react-markdown'
const CityTemplate = ({data}) => {
    const { frontmatter } = data.markdownRemark
    return(
    <Layout>
        <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-2 max-w-6xl mx-auto ">
            <div className="flex mb-8 md:gap-8 md:flex-nowrap flex-wrap ">
                <div className="md:w-2/3 w-full">                                  
                    {frontmatter?.html && 
                    <ReactMarkdown
                            children={frontmatter?.html}
                            components={{
                            p: ({ children }) => {
                                return (
                                    <p className="font-light text-sm text-gray-600 mb-12 max-w-full">
                                    {children}
                                </p>
                                )
                            },
                            h1: ({ children }) => {
                                return (
                                <h1 className="text-4xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-6">
                                    {children}
                                </h1>
                                )
                            },
                            h2: ({ children }) => {
                                return (
                                    <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-6">
                                    {children}
                                </h2>
                                )
                            },
                            h3: ({ children }) => {
                                return (
                                    <h3 className="md:text-3xl text-xl text-gray-600 uppercase mt-5 font-medium title pb-5 mt-7 text-center mb-10 ">
                                    {children}
                                </h3>
                                )
                            },
                            h4: ({ children }) => {
                                return (
                                <h4 className='text-2xl font-bold font3 mb-4'>
                                    {children}
                                </h4>
                                )
                            },
                            ul: ({ children })=> {
                                return (
                                <ul className="md:mb-16 mb-6 pl-2 list-disc">
                                            {children}
                                        </ul>
                                )
                            },
                            li: ({ children })=> {
                                return (
                                    <li className="text-sm font-normal mb-3">
                                            {children}
                                    </li>
                                )
                            },
                            }}
                        />
                    }  
                </div>
                <div className="md:w-1/3 w-full mt-4">
                    <SidebarForm />
                </div>
            </div>
        </div>
    </Layout>
)
    }
export const Head = (props) => <Seo title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'} path={props.location.pathname}  />
    
export const pageQuery = graphql`
  query CityByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
        frontmatter {
            title
            html
            meta {
                seoTitle
                seoDescription
            }
        }
    }
  }
`;
export default CityTemplate